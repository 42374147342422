.user--login-modal {
  z-index: 999;
}

.section-layout{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
  -ms-flex:1 0 auto;
  flex:1 0 auto;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -ms-flex-line-pack:start;
  align-content:flex-start;
  z-index: 999;
}
.button,.cards-list__button,.info-card__button,.radio-button{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  padding:0 1.5rem;
  border-width:.125rem;
  border-style:solid;
  font-weight:700;
  text-align:center;
  white-space:nowrap;
  cursor:pointer
}
.button{
  height:2.5rem;
  border-radius:1.25rem;
  line-height:2.3
}
.button:hover,.cards-list__button:hover,.info-card__button:hover{
  background-color:#1e1450;
  color:#fff
}
.button--large,.cards-list__button,.info-card__button{
  height:3rem;
  padding:0 2.5rem;
  border-radius:1.5rem;
  line-height:2.8
}
.button,.cards-list__button,.info-card__button{
  border-color:#1e1450;
  color:#1e1450;
  background-color:#fff
}
.button--inverse{
  background-color:#1e1450;
  border-color:#1e1450;
  color:#fff
}
.button--inverse:hover{
  border-color:#1e1450;
  color:#1e1450;
  background-color:#fff
}
.button--outline,.challenge-tour .button--outline,.g4d-tour .button--outline,.legends-tour .button--outline,.pga .button--outline,.rolex-series .button--outline{
  background-color:transparent
}
.button--outline:hover{
  border-color:#1e1450;
  background-color:#1e1450;
  color:#fff
}
div.user--module, div.user--registration-modal {
  display: block;
}
div.user--module select, div.user--registration-modal select {
  position: relative;
  width: 100%;
  padding: 0.625rem 2rem 0.625rem 0.625rem !important;
  border: 0;
  border-width: 0.0625rem !important;
  border-style: solid !important;
  border-radius: 0.25rem !important;
  border-color: #979797;
  outline: none;
  background: #fff;
  font-family: inherit;
  cursor: pointer;
}
div.user--module select option, div.user--registration-modal select option {
  font-family: inherit;
  font-weight: inherit;
}
.user--modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(6,0,22,.7);
  overflow: auto;
  z-index: 11;
}
@media only screen and (min-width: 62rem) {
  .user--modal-overlay {
    top: 0;
    z-index: 9;
 }
}
.user__close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  height: 2.5rem;
  color: #1d1d1d;
  cursor: pointer;
  z-index: 10;
}
@media only screen and (min-width: 62rem) {
  .user__close-button {
    top: 1rem;
    right: 1rem;
 }
}
@media only screen and (min-width: 62rem) {
  .user__close-button--login {
    color: #fff;
 }
}
.user__login-modal, .user__delete-account-modal {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #1d1d1d;
  font-size: 0.75rem;
  overflow: auto;
  z-index: 9;
}
.user__login-modal input, .user__delete-account-modal input, .user__login-modal select, .user__delete-account-modal select, .user__login-modal button, .user__delete-account-modal button {
  font-size: 0.75rem;
  line-height: 1.2;
}
@media only screen and (min-width: 48rem) {
  .user__login-modal, .user__delete-account-modal {
    font-size: inherit;
 }
  .user__login-modal input, .user__delete-account-modal input, .user__login-modal select, .user__delete-account-modal select, .user__login-modal button, .user__delete-account-modal button {
    font-size: 0.875rem;
    line-height: 1.3;
 }
}
@media only screen and (min-width: 62rem) {
  .user__login-modal, .user__delete-account-modal {
    width: 56.25rem;
    max-width: 100%;
    height: auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0.3125rem;
    overflow: hidden;
 }
}
.user__login-modal .gigya-layout-cell, .user__delete-account-modal .gigya-layout-cell {
  position: relative;
}
.user--logo-container {
  position: absolute;
  top: 2rem;
  left: 12%;
}
@media only screen and (min-width: 62rem) {
  .user--logo-container {
    top: 1.125rem;
    left: 1.125rem;
 }
}
.user--modal-contents {
  position: relative;
  width: 100%;
}
.user--modal-contents .gigya-screen-loader {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.user--modal-contents .gigya-screen-dimmer {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.user__login-modal .user--modal-contents {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  background-color: #fff;
}
@media only screen and (min-width: 62rem) {
  .user__login-modal .user--modal-contents {
    flex-direction: row;
    height: 100%;
 }
}
.user--registration-modal .user--modal-contents {
  padding: 0 12%;
}
@media only screen and (min-width: 48rem) {
  .user--registration-modal .user--modal-contents {
    padding: 0 6.375rem 4rem;
 }
}
.user--registration-modal .user--modal-contents .user--gigya-register-screen {
  position: relative;
  width: 100%;
  min-height: 24rem;
}
@media only screen and (min-width: 78.75rem) {
  .user--registration-modal .user--modal-contents .user--gigya-register-screen.gigya-register-screen {
    min-height: 0;
 }
}
.user--registration-modal .user--modal-contents .user--gigya-register-screen:not(.has-loaded) .user--bottom-line {
  display: none;
}
.user__gigya-login-screen {
  position: relative;
  flex-basis: 100%;
  min-height: 27rem;
  padding: 0 12%;
}
@media only screen and (min-width: 62rem) {
  .user__gigya-login-screen {
    max-width: 31.125rem;
    min-height: 100%;
    padding-right: 3.625rem;
    padding-left: 3.625rem;
 }
  .user__gigya-login-screen--full-width {
    max-width: none;
 }
}
.user__gigya-login-screen--full-width {
  padding-bottom: 3rem;
}
.user__gigya-login-screen--full-width + .user__close-button--login {
  color: #000;
}
.user__gigya-login-screen--full-width .button {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
.user__gigya-login-screen .gigya-screen-content {
  margin-top: 2rem;
}
.user--divider {
  margin: 1.25rem 0;
  padding: 0 20%;
  font-size: 0.875rem;
  text-align: center;
}
.user--divider:before, .user--divider:after {
  display: inline-block;
  position: relative;
  width: 7.3125rem;
  height: 0.0625rem;
  background-color: #f5f5f5;
  content: '';
  vertical-align: middle;
}
.user--divider:before {
  right: 1rem;
  margin-left: -50%;
}
.user--divider:after {
  left: 1rem;
  margin-right: -50%;
}
.user--input {
  margin-top: 1rem;
}
.user--input > input {
  display: block;
  width: 100%;
  padding: 0.625rem !important;
  border-width: 0.0625rem !important;
  border-style: solid !important;
  border-radius: 0.25rem !important;
  background-color: #fff;
}
.user--input > input:not(.gigya-error) {
  border-color: #979797;
}
.user--input > label {
  color: #333;
  font-size: 0.75rem;
  text-align: left;
}
.user__linked-accounts-input {
  max-width: 30rem;
  margin: 0.625rem auto;
}
.user--title {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
@media only screen and (min-width: 48rem) {
  .user--title {
    font-size: 1.5rem;
 }
}
.user--title.link-accounts-main-header {
  font-size: 1.5rem;
}
@media only screen and (min-width: 48rem) {
  .user--title.link-accounts-main-header {
    font-size: 2rem;
 }
}
.user--title.link-accounts-sub-header {
  font-size: 1.5rem;
}
.user--title.social-login-title {
  color: #4c4a6c;
  font-size: 0.875rem;
  font-weight: 400;
}
@media only screen and (min-width: 48rem) {
  .user--title.social-login-title {
    font-size: 1.5rem;
 }
}
.user--remember-me {
  margin-top: 0.75rem;
  color: #979797;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  text-align: left;
}
.user--remember-me > input {
  border: solid 0.125rem #bababa;
  border-radius: 0.25rem;
  vertical-align: bottom;
}
.user--forgot-password {
  display: block;
  width: 100%;
  margin-top: 0.75rem;
  color: #1d1d1d;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  text-align: left;
}
@media only screen and (min-width: 23.4375rem) {
  .user--forgot-password {
    position: absolute;
    right: 0;
    width: auto;
    z-index: 1;
 }
}
.gigya-link-account-screen .user--forgot-password {
  position: static;
  max-width: 480px;
  margin: 0 auto;
  text-align: right;
}
.button.user--login-button {
  margin-top: 1.5rem;
  border-color: #1e1450;
}
@media only screen and (min-width: 62rem) {
  .button.user--login-button {
    margin-top: 2.5rem;
 }
}
.button.user--login-button > input {
  width: 100%;
  margin: -0.125rem;
  padding: 0.6875rem;
  color: #1e1450;
  font-weight: 700;
  cursor: pointer;
}
.button.user--login-button:hover {
  background-color: #1e1450;
}
.button.user--login-button:hover > input {
  color: #fff;
}
.user--social-icons {
  width: 100%;
}
.user--social-icons .gigya-social-login-container {
  width: auto !important;
  height: auto !important;
  margin-bottom: -2rem;
  padding-top: 1.5rem;
}
.user--social-icons .gigya-social-login-container div {
  width: auto !important;
  height: auto !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container {
  display: table;
  width: auto !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row span {
  width: auto !important;
  height: auto !important;
  margin: 0 1rem !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row span > button {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: contain !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row span[gigid='facebook'] > button {
  background-image: url('https://www.europeantour.com/dist/img/facebook.svg') !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row span[gigid='googleplus'] > button {
  background-image: url('https://www.europeantour.com/dist/img/google.svg') !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row span[gigid='apple'] > button {
  background-image: url('https://www.europeantour.com/dist/img/apple.svg') !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row button {
  width: 2.75rem !important;
  height: 2.75rem !important;
}
.user--social-icons .gigya-social-login-container .gigya-login-providers-container .gigya-login-provider-row button > * {
  display: none !important;
}
.user--registration-modal {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  font-size: 0.75rem;
  /* overflow: hidden; */
  z-index: 9;
}
.user--registration-modal input, .user--registration-modal select {
  font-size: 0.875rem;
  line-height: 1.3;
}
@media only screen and (min-width: 48rem) {
  .user--registration-modal {
    border-radius: 0.3125rem;
    font-size: inherit;
 }
  .user--registration-modal input, .user--registration-modal select {
    font-size: inherit;
    line-height: 1.5;
 }
}
/*@media only screen and (min-width: 78.75rem) {*/
/*  .user--registration-modal {*/
/*    width: 75rem;*/
/*    margin-top: 1.5rem;*/
/*    margin-bottom: 1.5rem;*/
/* }*/
/*}*/
.user--registration-modal .gigya-layout-cell {
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
}
.user--registration-modal .gigya-layout-cell .user--sign-up-input {
  position: relative;
  flex-basis: 98%;
  margin: 1rem 1%;
}

.user--dob-input {
  flex-basis: 31.33% !important;
}
@media only screen and (min-width: 48rem) {
  .user--registration-modal .gigya-layout-cell .user--sign-up-input {
    flex-basis: 48%;
 }
}
.user--registration-modal .gigya-layout-cell .user--sign-up-input > label > label {
  color: inherit;
}
.user--registration-modal .gigya-layout-cell .user--sign-up-input .gigya-error-msg {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.user--dob-input {
  flex-basis: 31.3333%;
}
@media only screen and (min-width: 48rem) {
  .user--dob-input {
    flex-basis: 14.6665%;
    max-width: 14.6665%;
 }
}
.user--dob-input > label {
  display: inline-block;
  width: 7rem;
}
.user--dob-input + .user--dob-input > label > label {
  display: none;
}
.user--not-yet-registered {
  position: relative;
  height: 100%;
  background-color: #fff;
  color: #fff;
  text-align: center;
  overflow: hidden;
  z-index: 4;
}
@media only screen and (min-width: 62rem) {
  .user--not-yet-registered {
    min-width: 25.125rem;
 }
}
.user--not-yet-registered .layout--login-oval-right {
  display: none;
}
@media only screen and (min-width: 62rem) {
  .user--not-yet-registered .layout--login-oval-right {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
 }
}
.user--not-yet-registered .layout--login-oval-bottom {
  display: block;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 62rem) {
  .user--not-yet-registered .layout--login-oval-bottom {
    display: none;
 }
}
.user--not-yet-registered .layout--login-oval-bottom > svg {
  display: block;
  width: 100%;
  height: auto;
}
.user--not-yet-registered > * {
  position: relative;
  z-index: 1;
}
.user-not-yet-registered-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 2rem;
  background-color: #1e1450;
}
@media only screen and (min-width: 62rem) {
  .user-not-yet-registered-contents {
    padding: 2rem 0;
    background-color: transparent;
 }
}
.button.user--signup-button {
  border-color: #fff;
  background-color: transparent;
  position: relative;
  width: 75%;
  padding: 0.5625rem;
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 3.4375rem;
  background: none;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
@media only screen and (min-width: 62rem) {
  .button.user--signup-button {
    width: 10.5rem;
    margin: 0 auto;
 }
}
.button.user--signup-button:hover {
  background-color: #fff;
  color: #1d1d1d;
}
.user--dropdown {
  position: relative;
}
.user--dropdown::after {
  position: absolute;
  top: 56%;
  right: 0.625rem;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.6rem;
  transform: rotate(135deg);
  border-width: 0.125rem 0.125rem 0 0;
  border-style: solid;
  border-color: #1e1450 #1e1450 transparent transparent;
  content: '';
  pointer-events: none;
}
@media only screen and (min-width: 78.75rem) {
  .gigya-register-screen .user--submit-button > input {
    display: table;
 }
}
.button.user--submit-button {
  width: 12.5rem;
  margin: 2rem auto;
  border: solid 0.125rem #1e1450;
  border-radius: 3.4375rem;
  text-align: center;
  cursor: pointer;
}
.button.user--submit-button > input {
  width: 100%;
  margin: -0.125rem;
  padding: 0.75rem;
  color: #1e1450;
  font-weight: 700;
  cursor: pointer;
}
.button.user--submit-button:hover {
  background-color: #1e1450;
}
.button.user--submit-button:hover > input {
  color: #fff;
}
.user--bottom-line {
  width: 98%;
  margin: 4rem 1% 0;
  border-top: 0.125rem solid #f5f5f5;
}
.user--have-account-container {
  position: relative;
}
.user--have-account-text {
  width: 100%;
  margin: 0.9375rem 0;
  text-align: center;
}
.user--have-account-text > a {
  color: #1e1450;
  cursor: pointer;
}
.user--agree-to-text {
  display: inline-block;
  width: 98%;
  height: 4.25rem;
  margin-top: 1.5rem;
  margin-left: 1%;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
}
.user--agree-to-text .terms-link {
  text-decoration: underline;
}
@media only screen and (min-width: 78.75rem) {
  .user--agree-to-text {
    height: 2.25rem;
    padding: 0 23%;
 }
}
@media only screen and (min-width: 48rem) {
  .user--agree-to-text {
    font-size: 0.875rem;
 }
}
.user--password-forgot-title {
  margin-top: 2.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}
@media only screen and (min-width: 48rem) {
  .user--password-forgot-title {
    font-size: 1.5rem;
 }
}
.user--forgot-password-text {
  display: inline-block;
  width: 100%;
  margin: 1.5rem 0 2rem;
  color: #4c4a6c;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}
.user--send-password-button {
  position: relative;
  left: 50%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  transform: translateX(-50%);
}
.user--send-password-button > input {
  width: 100%;
  margin: -0.125rem;
  padding: 0.6875rem;
  color: #1e1450;
  font-weight: 700;
  cursor: pointer;
}
.user--send-password-button:hover {
  background-color: #1e1450;
}
.user--send-password-button:hover > input {
  color: #fff;
}
.gigya-verification-pending-screen + .user--have-account-container .user--have-account-text {
  top: -3rem;
}
.user--verify-text {
  display: inline-block;
  padding: 7.5rem 0 2rem;
  font-size: 0.875rem;
  text-align: center;
}
@media only screen and (min-width: 48rem) {
  .user--verify-text {
    font-size: 1rem;
 }
}
.user--input-email {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 48rem) {
  .user--input-email {
    width: 50%;
 }
}
.user--verify-submit-button {
  width: 12.5rem;
  margin: 2.5rem auto 0;
  border: solid 0.125rem #1e1450;
  border-radius: 3.4375rem;
  text-align: center;
  cursor: pointer;
}
.user--verify-submit-button > input {
  margin: -0.125rem;
  color: #1e1450;
  font-weight: 700;
  cursor: pointer;
}
.user--verify-submit-button:hover {
  background-color: #1e1450;
}
.user--verify-submit-button:hover > input {
  color: #fff;
  cursor: pointer;
}
.login-modal-carousel {
  width: 11rem;
  height: auto;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.login-modal-carousel .ddd-swiper--marker-type-dots .ddd-swiper__mask {
  overflow: hidden;
}
.login-modal-carousel .ddd-swiper--marker-type-dots .ddd-swiper__mask .ddd-swiper__marker {
  color: #fff;
}
.login-modal-carousel .ddd-swiper__container {
  width: 33rem;
}
.login-modal-carousel__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 11rem;
}
.gigya-link-account-screen {
  padding-top: 1rem;
}
.gigya-link-account-screen a {
  font-size: 0.875rem;
}
.user__delete-account-modal {
  color: #fff;
}
.user--divider {
  margin: 1.25rem 0;
  padding: 0 20%;
  font-size: 14px;
  text-align: center;
}

.user--divider:before,
.user--divider:after {
  display: inline-block;
  position: relative;
  width: 2.875rem;
  height: 0.0625rem;
  background-color: #f5f5f5;
  content: '';
  vertical-align: middle;
}

.user--divider:before {
  right: 1rem;
  margin-left: -50%;
}

.user--divider:after {
  left: 1rem;
  margin-right: -50%;
}

@media only screen and (max-width: 470px) {
  .user--divider>span {
      display: inline-block;
  }

  .user--divider:before,
  .user--divider:after {
      width: 46px;
  }
}
