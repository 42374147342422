/* autoprefixer grid: autoplace */

:root {
    --fontFamilyBase: BrownPro, Arial, sans-serif;

    --primary1: #1E1450;
    --primary1-75: #404BE8;
    --primary1-50: #8087F0;
    --primary1-25: #BFC3F7;
    --primary1-10: #E6E7FC;

    --primary2: #1E1450;
    --primary2-75: #454175;
    --primary2-50: #8380A3;
    --primary2-25: #C1C0D1;

    --background-blue: #000FE1;
    --background-white: #FFFFFF;

    --text-dark-blue: #070247;
    --text-dark-blue-75: #454175;
    --text-dark-blue-25: #C1C0D1;
    --text-white: #FFFFFF;

    --border-primary: #C4C2DE;

    --action-valid: #057D02;
    --action-invalid: #AD0202;
    --action-live: #057D02;
    --action-locked: #3E4AEE;
    --action-correct-answer: #B5B3C9;

    --action-tint-valid: rgba(5, 125, 2, 0.1);
    --action-tint-invalid: rgba(173, 2, 2, 0.1);
    --action-tint-selected: rgba(193, 192, 209, 0.4);


    --gradientStart: #352f8f;
    --gradientStop: #16061f;

    --accent1: #FF2261;
    --accent2: #FF3C14;
    --accent3: #00E68C;
    --accent4: #3230BE;
    --accent5: #EBB700;

    --textColor1: #202020;
    --textColor2: #070247;

    --background: #F9F9F9;
}

html, body {
    background: var(--background);
    min-height: 100%;
    height: 100%;
    color: var(--textColor1);
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--fontFamilyBase);
    -webkit-text-size-adjust: none;
}

body {
    background: radial-gradient(rgb(153, 153, 153) 0%, rgb(219, 219, 219) 25%);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
    font-weight: bold;
}

h1 {
    font-size: 44px;
    line-height: 46px;
}

h2 {
    font-size: 36px;
    line-height: 40px;
}

h3 {
    font-size: 28px;
    line-height: 32px;
}

h4 {
    font-size: 24px;
}

input,
button,
select {
    outline: none;
    font-family: var(--fontFamilyBase);
}

button {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
    font-size: 16px !important;
}


/*Fix for popover arrow if needed*/
.overflow-popover > .MuiPaper-root {
    overflow: visible;
}

.header-sharing > .MuiPaper-root {
    transform: translateY(-8px) !important;
}


/*!* Preload Landing images *!*/
/*body::after{*/
/*	!* hide images *!*/
/*	position: absolute;*/
/*	width:0;*/
/*	height:0;*/
/*	overflow:hidden;*/
/*	z-index:-1;*/

/*	!* load images *!*/
/*	content: url("../img/bg-landing.jpg")*/
/*	url("../img/bg-landing-gradient.png")*/
/*	url("../img/bg-landing-mobile.jpg")*/
/*	url("../img/bg-landing-mobile-gradient.png")*/
/*	url("../img/bg-login.jpg")*/
/*	url("../img/bg-login-mobile.jpg")*/
/*	url("../img/bg-login-mobile-gradient.png")*/
/*	url("../img/bg-registration.jpg")*/
/*	url("../img/bg-registration-mobile.jpg")*/
/*	url("../img/bg-registration-mobile-gradient.png");*/
/*}*/
